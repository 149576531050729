body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Arial Narrow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div.ant-select-selector {
  color: #ffffff !important;
  background: #122C4D !important;
  border-color: #122C4D !important;
}
.cradprojectframe .ant-card-meta-title {
  color: white !important;
}
.Dropdownlayout {
  text-align: start !important;
  margin: 0 0 6px 0 !important;
}
.nav {
  display: flex !important;
  flex-wrap: wrap !important;
  padding: 99px 120px;
  list-style: none !important;
}
.Overflow-Crad-Overview {
  padding: 97px 0 26px 0;
  overflow-x: hidden;
  height: 100%;
}
.Overflow-Crad-Project {
  overflow-x: hidden;
  height: 100%;
}
.Siderbackdrop {
  background: rgba(18, 44, 77, 0.8) !important;
  -webkit-backdrop-filter: blur(2px) !important;
  backdrop-filter: blur(2px) !important;
  /* width: 366px !important; */
  font-weight: bold !important;
  padding: 0 0 59px 0;
  overflow: hidden;
  text-align: center !important;
  min-height: 120px !important;
  height: 100%;
  color: #ffffff !important;
  /* flex: 0 0 366px !important; */
  /* max-width: 366px !important; */
  /* min-width: 366px !important; */
}
.Notification {
  background: rgba(18, 44, 77, 0.8) !important;
  -webkit-backdrop-filter: blur(2px) !important;
  backdrop-filter: blur(2px) !important;
  /* width: 366px !important; */
  padding: 0 0 59px 0;
  overflow: hidden;
  text-align: center !important;
  min-height: 120px !important;
  height: 100%;
  color: #ffffff !important;
  /* flex: 0 0 366px !important; */
  /* max-width: 366px !important; */
  /* min-width: 366px !important; */
}
.Notification-moniter {
  background: rgba(18, 44, 77, 0.8) !important;
  -webkit-backdrop-filter: blur(2px) !important;
  backdrop-filter: blur(2px) !important;
  /* width: 366px !important; */
  /* font-weight: bold !important; */
  padding: 0 0 0 15px;
  overflow: hidden;
  text-align: center !important;
  min-height: 120px !important;
  height: 100%;
  color: #ffffff !important;
  /* flex: 0 0 366px !important; */
  /* max-width: 366px !important; */
  /* min-width: 366px !important; */
}

.Siderbackdrop-moniter {
  background: rgba(18, 44, 77, 0.8) !important;
  -webkit-backdrop-filter: blur(2px) !important;
  backdrop-filter: blur(2px) !important;
  /* width: 366px !important; */
  font-weight: bold !important;
  padding: 0 0 0 15px;
  overflow: hidden;
  text-align: center !important;
  min-height: 120px !important;
  height: 100%;
  color: #ffffff !important;
  /* flex: 0 0 366px !important; */
  /* max-width: 366px !important; */
  /* min-width: 366px !important; */
}
.card-category {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  border-radius: 12px !important;
  transition: box-shadow 0.2s, border-color 0.2s !important;
}

.ovw-card li {
  width: 25%;
  /* padding: 0px 24px 87px 24px; */
}

.cradprojectframe.borderframe {
  height: 364px !important;
  width: 100% !important;
  background: #122c4d !important;
  border-radius: 12px !important;
  padding: 10px !important;
  cursor: pointer;
}
.card-category:hover {
  cursor: pointer !important;
  border-radius: 12px !important;
  border-color: transparent !important;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%) !important;
}
.cradprojectframe.borderframe .size-frame {
  height: 344px !important;
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
  border-radius: 12px !important;
}
.cradprojectframe.borderframe .box_inframe {
  background: rgba(18, 44, 77, 0.9) !important;
  padding: 10px 24px;
  width: -webkit-fill-available !important;
  height: -webkit-fill-available !important;
  position: absolute !important;
  top: 257px;
}
.cradprojectframe.borderframe .box_title {
  text-transform: capitalize !important;
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;
}
.cradprojectframe.borderframe .gap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.collapse-frame .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 23px 18px !important;
  background: #122c4d !important;
  border-radius: 8px !important;
}
.rangepicker-date.ant-picker {
    background: rgba(0, 0, 0, 0.15) !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
 }
 :where(.css-dev-only-do-not-override-1n7nwfa).ant-layout *  {
  color: white !important;
 }
.collapse-frame
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 31px !important;
}
.collapse-frame
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 !important;
}
.collapse-frame
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: unset !important;
}
.collapse-frame
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0px solid #f0f0f0 !important;
  border-bottom: 0px solid #f0f0f0 !important;
  background: rgba(0, 0, 0, 0.1) !important;
  left: unset !important;
  /* border-radius: 8px; */
}
.collapse-frame .ant-table-thead {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.bell-navbar .ant-badge .ant-badge-count {
  z-index: 100 !important;
  box-shadow: unset !important;
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.collapse-frame .ant-typography {
  margin-bottom: 0;
}
.gap .ant-badge .ant-badge-count {
  z-index: 100 !important;
  box-shadow: unset !important;
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.siderbarframe {
  color: #ffffff !important;
}
.siderbarframes {
  font-family: "Arial Narrow";
  color: #ffffff !important;
}
.siderbarframes .hr-title-noti {
  margin: 0 28px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
}
.siderbarframe .ant-divider {
  margin: 0 !important;
  border-block-start: 1px solid rgba(255, 255, 255, 0.25) !important;
}
.card-filterbar .ant-drawer .ant-drawer-mask {
  background: transparent !important;
}
.card-filterbar .ant-drawer-right > .ant-drawer-content-wrapper {
  top: 180px !important;
  margin: 3px 20px 20px 20px !important;
  border-radius: 15px !important;
  /* height: 100px; */
}
.card-filterbar .ant-drawer .ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
}

.card-filterbar .ant-drawer .ant-drawer-content {
  background: #0f2035 !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 15px !important;
}
.card-filterbar .ant-drawer .ant-drawer-body {
  padding: 0px 23px !important;
  margin: 25px 6px !important;
}

.card-filterbar .ant-drawer .ant-drawer-footer {
  padding: 0 23px 23px 23px !important;
}
/* width */
::-webkit-scrollbar {
  width: 12px !important;
}

/* Track */
/* .card-filterbar .filterbar-body ::-webkit-scrollbar-track { */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.25) !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
}

/* Handle */
/* .card-filterbar .filterbar-body ::-webkit-scrollbar-thumb { */
::-webkit-scrollbar-thumb {
  background: rgb(230, 230, 230) !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
}

/* Handle on hover */
/* .card-filterbar .filterbar-body ::-webkit-scrollbar-thumb:hover { */
::-webkit-scrollbar-thumb:hover {
  background: rgba(200, 200, 200, 0.8) !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: unset !important;
}
.ant-space-item {
  font-family: "Arial Narrow" !important;
  /* font-size: 32px !important; */
}
.ant-collapse-header-text {
  text-transform: capitalize;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected:hover {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-dropdown .ant-dropdown-menu {
  padding: 0 !important;
}
.htr-frame {
  height: 627px !important;
  /* overflow-y: scroll !important; */
}
.htr-frame .ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 0 !important;
}
.htr-frame
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0 !important;
  background: rgba(0, 0, 0, 0.25) !important;
}
.htr-frame
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: unset !important;
}
.htr-frame .ant-table-thead {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.tableProject .ant-table {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
}

.tableProject .ant-table-cell {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
  left: unset !important;
}

.tableProject .ant-table-row:hover {
  background: rgba(0, 0, 0, 0.26) !important;
  cursor: pointer !important;
}
.tableProject .ant-table-wrapper .ant-table-thead > tr > th {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding: 4px 4px !important;
}
.tableProject .ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding: 16px 8px;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 3px solid #2b4463 !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td
  :hover {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 0px solid #2b4463 !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.tableProject-detail .ant-table {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.tableProject-detail .ant-table-cell {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
  left: unset !important;
}
.tableProject-detail .ant-table-row:hover {
  background: rgba(0, 0, 0, 0.26) !important;
  cursor: pointer !important;
}
.tableProject-detail .ant-table-wrapper .ant-table-thead > tr > th {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  padding: 16px 16px !important;
}
.tableProject-detail .ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding: 16px 16px;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 3px solid #2b4463 !important;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td
  :hover {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 0px solid #2b4463 !important;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}
.tableNotofication-detail .ant-table {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.tableNotofication-detail .ant-table-cell {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
  left: unset !important;
}
.tableNotofication-detail .ant-table-row:hover {
  background: rgba(0, 0, 0, 0.26) !important;
  cursor: pointer !important;
}
.tableNotofication-detail .ant-table-wrapper .ant-table-thead > tr > th {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 16px 16px !important;
}
.tableNotofication-detail .ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  color: #ffffff;
  padding: 16px 16px;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 3px solid #2b4463 !important;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td
  :hover {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 0px solid #2b4463 !important;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableNotofication-detail
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.tableProject-htr .ant-table {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.tableProject-htr .ant-table-cell {
  background: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
  left: unset !important;
}
.tableProject-htr .ant-table-row:hover {
  background: rgba(0, 0, 0, 0.26) !important;
  cursor: pointer !important;
}
.tableProject-htr .ant-table-wrapper .ant-table-thead > tr > th {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding: 16px 16px !important;
}
.tableProject-htr .ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  padding: 16px 16px;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 3px solid #2b4463 !important;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td
  :hover {
  border-top: 0px solid #2b4463 !important;
  border-bottom: 0px solid #2b4463 !important;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}
.tableProject-htr
  .ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover:last-child,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row.ant-table-row-selected
  > td:last-child {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.title_rs {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 26px;
  color: #ffffff !important;
}
.title_btr {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 23px;
  color: #ffffff !important;
}
.statusName {
  font-size: 23px;
}
.textName_rs {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px;
  color: #ffffff !important;
  margin: 18px 0 !important;
}
.width_backbox {
  padding: 18px 8px;
  width: 100%;
}
.width_icon_sv_1 {
  width: 35px;
}
.width_icon_sv_2 {
  width: 35px;
}
.width_icon_sv_3 {
  width: 38px;
  display: flex !important;
}
.ant-table {
  width: 100% !important;
  overflow-y: hidden !important;
}
.clocktime {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-size: 24px;
  font-weight: 700 !important;
}
.tableProject .ant-table-wrapper .ant-table-filter-trigger {
  margin-inline: 0px 0px;
}
.ant-dropdown .ant-table-filter-dropdown {
  border-radius: 8px;
}
.ant-empty-description {
  color: #ffffff;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  font-family: "Arial Narrow" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background: rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 13px !important;
}
.ant-layout {
  background: rgb(18, 44, 77);
}
.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: rgba(255, 255, 255, 0.8) !important;
}
.input_ps .ant-input-affix-wrapper > input.ant-input {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.tableProject
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}
.tableProject .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: unset !important;
}

.sizeCol .ant-col-xxl-4 {
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}
.scroll-containers {
  height: 100%;
}
.scroll-container {
  height: 867px;
}
.scroll-container-detailproject {
  height: 716px;
}
.indiana-scroll-container {
  overflow: auto !important;
}
.detail-rooms .ant-divider {
  border-block-start: 3px solid rgba(232, 232, 232, 0.49);
}
.boxframe {
  width: 100%;
  background: linear-gradient(
    144.09deg,
    rgba(26, 62, 107, 0.95) 0%,
    rgba(18, 44, 77, 0.95) 100%
  );
  backdrop-filter: blur(2px);
  top: 0;
  right: 0;
  position: absolute;
  height: 100vh;
  transition: width 2s ease;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1600px) {
  .ovw-card li {
    width: 33.33%;
    /* padding: 30px; */
  }
  .title_rs {
    font-size: 22px;
  }
  .title_btr {
    font-size: 19px;
  }
  .statusName {
    font-size: 19px;
  }
  .textName_rs {
    font-size: 12px;
  }
  .width_backbox {
    padding: 18px 8px;
    width: 100%;
  }
  .width_icon_sv_1 {
    width: 18px;
  }
  .width_icon_sv_2 {
    width: 20px;
  }
  .width_icon_sv_3 {
    width: 22px;
  }
  .sizeCol .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 1400px) {
  .cradprojectframe.borderframe .size-frame {
    height: 250px;
  }
  .cradprojectframe.borderframe .box_inframe {
    padding: 16px 8px;
    /* top: 200px; */
    /* transition: all 0.3s; */
  }
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .title_rs {
    font-size: 20px;
  }
  .title_btr {
    font-size: 17px;
  }
  .statusName {
    font-size: 17px;
  }
  .textName_rs {
    font-size: 10px;
  }
  .width_backbox {
    /* padding: 10px 6px; */
    width: 100%;
  }
  .width_icon_sv_1 {
    width: 20px;
  }
  .width_icon_sv_2 {
    width: 23px;
  }
  .width_icon_sv_3 {
    width: 24px;
  }
  .sizeCol .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* .clocktime {
    font-size: 24px;
  } */
}
@media screen and (max-width: 1200px) {
  .ovw-card li {
    width: 50%;
    /* padding: 30px; */
  }
  .cradprojectframe.borderframe .size-frame {
    height: 315px;
  }
  .cradprojectframe.borderframe .box_inframe {
    padding: 20px 8px;
    top: 257px;
  }
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .title_rs {
    font-size: 20px;
  }
  .title_btr {
    font-size: 17px;
  }
  .statusName {
    font-size: 17px;
  }
  .textName_rs {
    font-size: 14px;
  }
  .width_icon_sv_1 {
    width: 23px;
  }
  .width_icon_sv_2 {
    width: 32px;
  }
  .width_icon_sv_3 {
    width: 32px;
  }
  .sizeCol .ant-col-xl-6 {
    display: block;
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  /* .clocktime {
    font-size: 24px;
  } */
}
@media screen and (max-width: 997px) {
  .ovw-card li {
    width: 50%;
    /* padding: 30px; */
  }
  .cradprojectframe.borderframe .size-frame {
    height: 315px;
  }
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .title_rs {
    font-size: 14px;
  }
  .title_btr {
    font-size: 12px;
  }
  .statusName {
    font-size: 12px;
  }
  .textName_rs {
    font-size: 12px;
  }
  .width_icon_sv_1 {
    width: 23px;
  }
  .width_icon_sv_2 {
    width: 32px;
  }
  .width_icon_sv_3 {
    width: 43px;
  }
  .sizeCol .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }

  /* .clocktime {
    font-size: 24px;
  } */
}
@media screen and (max-width: 992px) {
  .ovw-card li {
    width: 50%;
    /* padding: 30px; */
  }
  .cradprojectframe.borderframe .size-frame {
    height: 315px;
  }
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .title_rs {
    font-size: 26px;
  }
  .title_btr {
    font-size: 23px;
  }
  .statusName {
    font-size: 23px;
  }
  .textName_rs {
    font-size: 18px;
  }
  /* .clocktime {
    font-size: 14px;
  } */
  .sizeCol .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
}
@media screen and (max-width: 939px) {
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .nav {
    padding: 99px 10px;
  }
  .title_rs {
    font-size: 26px;
  }
  .title_btr {
    font-size: 23px;
  }
  .statusName {
    font-size: 23px;
  }
  .textName_rs {
    font-size: 18px;
  }
  .sizeCol .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  /* .clocktime {
    font-size: 14px;
  } */
}
@media screen and (max-width: 768px) {
  .ovw-card li {
    width: 100%;
    /* padding: 30px 0px; */
  }
  .cradprojectframe.borderframe .gap {
    flex-direction: row;
  }
  .nav {
    padding: 99px 20px;
  }
  .title_rs {
    font-size: 26px;
  }
  .title_btr {
    font-size: 23px;
  }
  .statusName {
    font-size: 23px;
  }
  .textName_rs {
    font-size: 18px;
  }
  /* .clocktime {
    font-size: 12px;
  } */

  .sizeCol .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
}
@media (max-width: 576px) {
  .sizeCol .ant-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  .boxframe {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 400px;
  }
  .boxframe {
    width: 60%;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 360px;
  }
  .boxframe {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 380px;
  }
  .boxframe {
    width: 40%;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    max-width: 520px;
  }
  .boxframe {
    width: 40%;
  }
}

@media (max-height: 835px) {
  .boxframe {
    height: auto;
  }
}
@media (max-height: 768px) {
  .boxframe {
    height: auto;
  }
}
@media (max-height: 576px) {
  .boxframe {
    height: auto;
  }
}
